import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Footer from '../components/Footer';
// import { Link } from 'gatsby';
import Scroll from '../components/Scroll';

const IndexPage = () => (
  <Layout>
    <Header />

    <section id="banner">
      <div>
        <p>
          Over 18 years of experience in various roles as a technical product owner, 
          web and cloud architect, full-stack developer, scrum master of co-sourced teams and
          agile practitioner.  <br />
          {/* with a keen interest in seeking out challenging technical roles <br />
          in building scalable distributed applications */}
        </p>
      </div>
      <div className="give-me-some-space">
        <h3>Expertise</h3>
        <ul>
          <li>
            Results-driven technical product owner with 4+ years of experience 
            in driving user acquisition, retention, and revenue growth through 
            strategic product management.
          </li>
          <li>
          Bring a unique blend of technical acumen and business insight, allowing me 
          to effectively bridge the gap between engineering and stakeholders.
          </li>
          <li>
            8 years of experience in technical leadership role that involves
            interacting with stakeholders for requirements, planning,
            estimation, design, <br />
            development, reviews, automation, implementing best practices and security audits.
          </li>
          <li>
            Involved in end-to-end delivery of enterprise software products,
            architectural research, software design and agile team management of
            co-sourced teams.
          </li>
          {/* <li>
            Collaborated and managed subsystem delivery from outsourced
            development partners
          </li> */}
          <li>Adept at leveraging data-driven insights, cross-functional collaboration, 
            and user-centric approaches to optimize product experiences and achieve business objectives.</li>
          <li>Proven ability to develop and execute successful growth strategies in fast-paced, innovative 
            environments by leveraging market research and competitor analysis.</li>
          {/* <li>
            Meticulous, results-driven and analytical with an insatiable
            appetite to learn new technologies
          </li> */}
        </ul>
      </div>
      <footer>
        <Scroll type="id" element="key-skills">
          <a href="#key-skills" className="button style2 scrolly">
            Skills
          </a>
        </Scroll>
        <Scroll type="id" element="roles">
          <a href="#roles" className="button style2 scrolly">
            Roles
          </a>
        </Scroll>
        <Scroll type="id" element="experience-1">
          <a href="#experience-1" className="button style2 scrolly">
            Work Experience
          </a>
        </Scroll>
        <a
          href="https://blog.aunlead.com"
          target="_blank"
          rel="noopener noreferrer"
          className="button style2 scrolly"
        >
          Blog
        </a>
        <a
          href="/files/Rajat_Nair.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="button style2 scrolly"
        >
          Resume
        </a>
        <Scroll type="id" element="footer">
          <a href="#footer" className="button style2 scrolly">
            Contact
          </a>
        </Scroll>
      </footer>
    </section>

    <article id="key-skills" className="container box style3">
      <section>
        <header>
          <h3>Skills</h3>
        </header>
        {/* <div class="clearfix skillbar">
          <div class="skillbar-title" style="background:#2c3e50">
            <span>Python</span>
          </div>
          <div
            class="skillbar-bar"
            style="background: rgb(83, 110, 137) none repeat scroll 0% 0%; width: 50%;"
          ></div>
          <div class="skill-bar-percent">70%</div>
        </div> */}
        <h5>Frameworks / Libraries</h5>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Angular 2/5/11/18</span>
          </div>
          <div className="skillbar-bar expert"></div>
          <div className="skill-bar-percent">Expert</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>AngularJS 1.3</span>
          </div>
          <div className="skillbar-bar expert"></div>
          <div className="skill-bar-percent">Expert</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>ReactJS</span>
          </div>
          <div className="skillbar-bar intermediate"></div>
          <div className="skill-bar-percent">Intermediate</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>NodeJS</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <a
              href="https://nx.dev/angular"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>NX</span>
            </a>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Docker</span>
          </div>
          <div className="skillbar-bar expert"></div>
          <div className="skill-bar-percent">Expert</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Apache Mesos</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Marathon</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Dropwizard</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Swagger</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>LESS</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Liquibase</span>
          </div>
          <div className="skillbar-bar intermediate"></div>
          <div className="skill-bar-percent">Intermediate</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Gatsby</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Gauge/Taiko</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Asp.net MVC</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>.Net 1.1/2.0/3.5</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>.Net Core</span>
          </div>
          <div className="skillbar-bar intermediate"></div>
          <div className="skill-bar-percent">Intermediate</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Spring Framework/Boot</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Cypress</span>
          </div>
          <div className="skillbar-bar intermediate"></div>
          <div className="skill-bar-percent">Intermediate</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>Akita</span>
          </div>
          <div className="skillbar-bar intermediate"></div>
          <div className="skill-bar-percent">Intermediate</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>RamdaJS</span>
          </div>
          <div className="skillbar-bar advanced"></div>
          <div className="skill-bar-percent">Advanced</div>
        </div>

        <div className="clearfix skillbar">
          <div className="skillbar-title">
            <span>TypeORM</span>
          </div>
          <div className="skillbar-bar intermediate"></div>
          <div className="skill-bar-percent">Intermediate</div>
        </div>

        <hr />
        <h5>Languages</h5>

        <p>
          JavaScript, TypeScript, Java, C#, VB.net, Asp, HTML5, CSS, SQL,
          Bash/Batch scripting, Visual Basic 6.0, RxJava
        </p>
        <hr />
        <h5>IaaS</h5>
        <p>Amazon Web Services, Microsoft Azure, DigitalOcean, Scaleway</p>
        <hr />
        <h5>Middleware</h5>
        <p>Keycloak, HAProxy, Traefik</p>
        <hr />
        <h5>Data Repository</h5>
        <p>
          PostgreSQL, CouchDB, MySQL, Microsoft SQL Server
          2000/2005/2012, Sybase, Microsoft Access
        </p>
        <hr />
        <h5>Integration</h5>
        <p>
          Ansible, Puppet, Jenkins, Grunt, Webpack, Maven, Ant, Git, Quickbuild,
          SVN
        </p>
      </section>
    </article>

    <article id="roles" className="container box style3">
      <section>
        <header>
          <h3>Roles</h3>

          <h5>Technical Product Owner</h5>
          <p>
            <span className="project-title">
              DATA PROTECTOR: MICROSOFT365 INTEGRATION AND INTEGRATED REPORTING –
            </span>
            <span className="project-desc">
              To protect M365 cloud data along with performing predictive analysis of backup environments
            </span>
          </p>
          <ul>
            <li>
              Managed 3 agile teams of internal and outsourced partners
              to develop subsystem
            </li>
            <li>
              Coordinated with various stakeholders like product
              managers, release train engineers (RTE), legal and outsourced
              development partners
            </li>
            <li>
              Monitored release milestones using various metrics and took
              corrective actions as needed
            </li>
            <li>
              Was an integral part of design and architecture discussions along
              with sub-system development and code reviews
            </li>
            <li>
              Provided team with guidance on various technical issues and
              challenges
            </li>
            <li>Piloted the creation of UI and REST automation framework</li>
            <li>
              Refined recruitment processes with online/offline data
              repositories
            </li>
          </ul>
          <hr />
          
          <h5>Web Architect</h5>
          <p>
            <span className="project-title">DATA PROTECTOR - </span>
            <span className="project-desc">
              Backup and disaster recovery solution for large, complex, and
              heterogeneous IT environments.
            </span>
          </p>
          <ul>
            <li>
              Designed, architected and led the team that implemented the new
              Web UI which coexisted with traditional UI on MFC
            </li>
            <li>
              Interacted with stakeholders to gather requirements, update
              progress and conduct demos
            </li>
            <li>
              Developed dashboard, backup, restore, scheduler, telemetry and
              reporting UI components.
            </li>
            {/* <li>Developed plugin for virtual environments using VMware SDKs</li> */}
            <li>
              Developed white-labeled REST API documentation UI based on OpenAPI
              specification
            </li>
            <li>
              Created seed projects, development and best practices guides
            </li>
            <li>Instrumental in driving UI automation</li>
            <li>Migration of AngularJS to Angular</li>
            <li>
              Integration of UI builds with continuous integration (CI) build
              farms
            </li>
            <li>
              Implemented continuous inspection for code quality and security
            </li>
          </ul>

          <hr />

          <h5>Cloud Architect</h5>
          <p>
            <span className="project-title">
              COMMON APPLICATION FRAMEWORK (CAF) –{' '}
            </span>
            <span className="project-desc">
              Massively scalable and highly available collection of
              microservices
            </span>
          </p>
          <ul>
            <li>
              SaaS based microservices for Identity, Storage, Analytics,
              Auditing, Telemetry, and Data Processing
            </li>
            <li>
              Played the role of an architect, SAFe® scrum master and developer
              on CAF Telemetry
            </li>
            <li>Designed and implemented Docker container strategy for CAF</li>
            <li>
              Researched and defined continuous integration (CI) and continuous
              delivery (CD) methodologies with Docker, Maven, Jenkins and
              Artifactory
            </li>
            <li>
              Instrumental in choosing Java framework for CAF Telemetry, and
              Identity manager for CAF IDM
            </li>
            <li>
              Integrated Liquibase to managing and applying database schema
              changes
            </li>
            <li>
              Completed production setup and deployment of highly available
              cluster of CAF Telemetry on AWS using Apache Mesos and Marathon
            </li>
          </ul>
          <hr />

          <h5>Full-Stack Developer</h5>
          <p>
            <span className="project-title">CONNECTED MX (CMX) –</span>
            <span className="project-desc">Enterprise endpoint protection</span>
          </p>
          <ul>
            <li>
              Integral part of team which chose AngularJS as Web framework in
              2013
            </li>
            <li>Integrated Typescript with AngularJS</li>
            <li>
              Developed the file browse, policy management, asset sharing and
              restore components
            </li>
            <li>Developed REST APIs based on OpenAPI standards</li>
            <li>PostgreSQL was used as a data repository</li>
            <li>
              Integrated Liquibase to managing and applying database schema
              changes
            </li>
          </ul>
          <p>
            <span className="project-title">
              CONNECTED REPORTING SERVICES –
            </span>
            <span className="project-desc">
              Consolidated reporting platform for Connected Backup
            </span>
          </p>
          <ul>
            <li>
              Developed web console and REST APIs using Asp.net Razor and
              Asp.net MVC3
            </li>
            <li>
              Implemented single sign-on (SSO) support on web console to view
              and subscribe to reports
            </li>
            <li>
              Facilitated integration of SQL Server Integration Services (SSIS)
              and SQL Server Reporting Services (SSRS) into the application
            </li>
            <li>
              Customized SQL Server Reporting Services to display live previews
              of reports and to create report templates using RDLs
            </li>
          </ul>
          <p>
            <span className="project-title">
              CONNECTED CLASSIFY AND CONNECT –
            </span>
            <span className="project-desc">
              Simplifying the collection of endpoint data for eDiscovery
            </span>
          </p>
          <ul>
            <li>Developed the UI on ExtJS</li>
            <li>
              Backend was developed on Microsoft .Net 3.5 using Windows
              Communication Foundation (WCF) and Windows Workflow Foundation
            </li>
            <li>
              Utilized SQL Server Express and Apache CouchDB as data
              repositories
            </li>
          </ul>
          <p>
            <span className="project-title">SERVICE PROVIDER PORTAL –</span>
            <span className="project-desc">
              Centralized portal for service providers of AXA Gulf to access
              different applications and services
            </span>
          </p>
          <ul>
            <li>
              Used nascent Jquery library (2007) to implement Ajax based Web UI
              on .Net framework to enable insurance providers from different
              hospitals across AXA Gulf to support card-based authentication
              process to various policy holders
            </li>
            <li>
              Implemented user management system to provide a single sign-on
              (SSO) based, consolidated administrative interface to manage all
              users, entities, and master data
            </li>
          </ul>
        </header>
      </section>
    </article>

    <article id="experience-1" className="container box style1 right">
      <section>
        <div className="image content-text">
          <h3>Work Experience</h3>
          <h4>Microfocus</h4>
          <h6>(Formerly Hewlett Packard Enterprise)</h6>
          <h6>
            <i>Expert</i>
          </h6>
          <h6>October 2010 – Present</h6>
        </div>
        <div className="inner">
          <p>
            Worked on various Enterprise Information Management and Data
            Protection products across technology stacks.
          </p>
          <ul>
            <li>
              <p>
                <span className="project-title">DATA PROTECTOR - </span>
                <span className="project-desc">
                  Backup and disaster recovery solution for large, complex, and
                  heterogeneous IT environments.
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">
                  COMMON APPLICATION FRAMEWORK (CAF) –{' '}
                </span>
                <span className="project-desc">
                  Massively scalable and highly available collection of
                  microservices
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">CONNECTED MX (CMX) – </span>
                <span className="project-desc">
                  Enterprise endpoint protection
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">
                  CONNECTED REPORTING SERVICES –{' '}
                </span>
                <span className="project-desc">
                  Consolidated reporting platform for Connected Backup
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">
                  CONNECTED CLASSIFY AND CONNECT –{' '}
                </span>
                <span className="project-desc">
                  Simplifying the collection of endpoint data for eDiscovery
                </span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </article>

    <article id="experience-2" className="container box style1 right">
      <section>
        <div className="image content-text">
          <h3>Work Experience</h3>
          <h4>AXA</h4>
          <h6>
            <i>Senior Software Engineer</i>
          </h6>
          <h6>July 2007 - October 2010</h6>
        </div>
        <div className="inner">
          <p>
            Worked across multiple business verticals building custom solutions
            on Microsoft .Net platform
          </p>
          <ul>
            <li>
              <p>
                <span className="project-title">
                  SERVICE PROVIDER PORTAL -{' '}
                </span>
                <span className="project-desc">
                  Centralized portal for service providers of AXA Gulf to access
                  different applications and services
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">
                  EXPENSE TRACKING SYSTEM –{' '}
                </span>
                <span className="project-desc">
                  Provides users of AXA Assistance a platform to capture and
                  track IT vendor payments and follow up their IT budgets
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">
                  AUTHORIZATION AND PAYMENT SYSTEM –{' '}
                </span>
                <span className="project-desc">
                  Automated workflow system that enables AXA Asia Life to
                  capture and track all non-claim payments
                </span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </article>

    <article id="experience-3" className="container box style1 right">
      <section>
        <div className="image content-text">
          <h3>Work Experience</h3>
          <h4>Iqura Technologies</h4>
          <h6>
            <i>Software Engineer</i>
          </h6>
          <h6>September 2005 - July 2007</h6>
        </div>
        <div className="inner">
          <p>
            Worked on VB.net and VB6 solutions for domestic and international
            clients
          </p>
          <ul>
            <li>
              <p>
                <span className="project-title">
                  LAND AMERICA PROPERTY INSPECTION SERVICES –{' '}
                </span>
                <span className="project-desc">
                  Solution for certified inspectors perform comprehensive
                  inspections involving a home’s structural and mechanical
                  systems for homeowners and prospective buyers
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">
                  RESUME MANAGEMENT SYSTEM –{' '}
                </span>
                <span className="project-desc">
                  Process large volumes of mails for resumes, from mail accounts
                  using MAPI
                </span>
              </p>
            </li>
            <li>
              <p>
                <span className="project-title">
                  PRODCO'S PSREPORTS AND CPLINK REPORTS –{' '}
                </span>
                <span className="project-desc">
                  Real time retail footfall analysis
                </span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </article>

    <article id="experience-3" className="container box style1 right">
      <section>
        <div className="image content-text">
          <h3>Work Experience</h3>
          <h4>Spectron Electronics</h4>
          <h6>
            <i>Entrepreneur</i>
          </h6>
          <h6>May 2012 - May 2020</h6>
        </div>
        <div className="inner">
          <p>
            Startup which specialised in professional HVAC preventative and corrective maintenance along with providing
            turnkey solutions for customers.
          </p>
          <ul>
            <li>
              <p>
                <span className="project-title">
                Annual maintenance contract (AMC) –{' '}
                </span>
                <span className="project-desc">
                Preventative HVAC maintenance with large corporates.
                </span>
              </p>
            </li>
          </ul>
        </div>
      </section>
    </article>

    {/* <article id="education-1" className="container box style3">
      <section>
        <header>
          <h3>Education</h3>
        </header>
        <h5>H.K.B.K College of Engineering, Bangalore</h5>
        <p>Bachelor of Engineering - Computer Science (V.T.U)</p>
        <hr />
        <h5>Baldwin's Methodist College, Bangalore</h5>
        <p>Std 12 (PUC)</p>
        <hr />
        <h5>Frank Anthony Public School, Bangalore</h5>
        <p>Std 10 (ICSE)</p>
      </section>
    </article> */}

    {/* 
    <article className="container box style2">
      <header>
        <h2>Magnis parturient</h2>
        <p>
          Justo phasellus et aenean dignissim
          <br />
          placerat cubilia purus lectus.
        </p>
      </header>

      <div className="inner gallery">
        <Gallery
          images={ROW1_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
        <Gallery
          images={ROW2_IMAGES.map(({ src, thumbnail, caption }) => ({
            src,
            thumbnail,
            caption,
          }))}
        />
      </div>
    </article>

    <article className="container box style3">
      <header>
        <h2>Nisl sed ultricies</h2>
        <p>Diam dignissim lectus eu ornare volutpat orci.</p>
      </header>
      <form method="post" action="#">
        <div className="row gtr-50">
          <div className="col-6 col-12-mobile">
            <input
              type="text"
              className="text"
              name="name"
              placeholder="Name"
            />
          </div>
          <div className="col-6 col-12-mobile">
            <input
              type="text"
              className="text"
              name="email"
              placeholder="Email"
            />
          </div>
          <div className="col-12">
            <textarea name="message" placeholder="Message" />
          </div>
          <div className="col-12">
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" />
              </li>
            </ul>
          </div>
        </div>
      </form>
    </article>

    <article className="container box style3">
      <header>
        <h2>Elements</h2>
        <p>This starter has other elements.</p>
        <br />
        Check it out
        <Link to="/Element"> here </Link>
      </header>
    </article> */}

    <Footer />
  </Layout>
);

export default IndexPage;
